import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Zoom from "@mui/material/Zoom";
import Box from "@mui/material/Box";

export default function Results({ lsdResponse, service }) {
  //console.log(lsdResponse);


  if (!lsdResponse || lsdResponse.length === 0) {
    return (
      <div id={`converted${service}`}>
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <Typography variant="h6">There are no suppliers for the postcode you provided</Typography>
      </Box>
      </div>
    );
  }

  function truncateText(text, length) {
    if (text.length <= length) {
      return text;
    }
    return text.slice(0, length) + "...";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = lsdResponse.map((result, index) => (
    <Grid item key={index} xs={12} sm={12} md={12} lg={12}>
      <Zoom
        in={true}
        timeout={{ enter: 500, exit: 250 }}
        mountOnEnter
        unmountOnExit
      >
        <Card sx={{ mt: 2, p: 2, pb: 1, width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.1rem !important",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500 !important",
                  mb: "0px !important",
                }}
              >
                {truncateText(result.bn.title, 35)}
              </Typography>
            </Grid>


            <Grid item xs={12} sx={{ height: 30, pt: "0 !important" }}>
              <Typography
                color="text.secondary"
                sx={{
                  fontSize: "0.rem !important",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "1.5 !important",
                }}
              >
                {truncateText(result.bn.field_first_name, 10)}{" "}
                {truncateText(result.bn.field_last_name, 30)}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.0rem !important",
                
                  textOverflow: "ellipsis",
                  fontWeight: "400 !important",
                  mb: "0px !important",
                }}
              >
                {truncateText(result.bn.body, 250)}
              </Typography>
            </Grid>

            
              {result.price > 0 && (
                <Grid item xs={12} sx={{ height: 60,mt:2}}>
                <Typography
                  color="text.secondary"
                  textAlign="center"
                  sx={{
                    fontWeight: "600 !important",
                    fontSize: "1.6rem !important",
                  }}
                >
                  £{result.price}
                </Typography>
                </Grid>
              )}
            
        

            <Grid item xs={12} sx={{ height: 45, mt:2 }}>
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "0.875rem !important",
                  lineHeight: "1.3 !important",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                color="text.secondary"
              >
                {truncateText(result.sn.field_area_covered, 35)}
              </Typography>
            </Grid>
            <Grid item xs={10} container direction="row" alignItems="center">
              <a href={"tel:" + result.bn.field_main_contact_number}>
                <CallIcon color="primary" />
              </a>
              <a
                href={"tel:" + result.bn.field_main_contact_number}
                style={{ textDecoration: "none", mb: "0 !important" }}
              >
                <Typography
                  sx={{
                    mb: "0px !important",
                    lineHeight: "1.5 !important",
                    pb: "0px !important",
                    fontSize: "0.875rem !important",
                    fontWeight: "500 !important",
                    whiteSpace: "nowrap",
                  }}
                  color="text.secondary"
                >
                  &nbsp;{result.bn.field_main_contact_number}
                </Typography>
              </a>
            </Grid>
            <Grid item xs={2} textAlign="right">
              <a
                href={"mailto:" + result.bn.field_email.email}
                target="_blank"
                rel="noreferrer"
              >
                <EmailIcon color="primary" />
              </a>
            </Grid>
          </Grid>
        </Card>
      </Zoom>
    </Grid>
  ));

  return (
    <div id={`converted${service}`}>
    <Box sx={{ flexGrow: 1, pb:2 }}>
      <Grid container spacing={3}>
        {content}
      </Grid>
    </Box>
    </div>
  );
}

// PropTypes
Results.propTypes = {
  lsdResponse: PropTypes.array.isRequired,
  service: PropTypes.string.isRequired,
};
